<template>
  <router-view/>
</template>

<style>


.router-active  {
  color: #ffffff !important;
  font-weight: 600;
  background-color: #a0a0a0;
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem !important;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
  display: inline-block;
  transition: all 0.3s;
}
</style>
